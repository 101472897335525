import React from "react"
import Layout from "../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Md4Card from "../components/md4card"
import { graphql } from "gatsby"


export const data = graphql`
query($category: String!){
    allBlogDataset(filter: {Category: {eq: $category}}) {
      edges {
        node {
          Title
          SlugURL
          Category
          Excerpt
          FeaturedImageURL
        }
      }
    }
  }
`

export const seo = {
    title: 'Blogs | Crampete',
    description: 'In-depth blog articles on up-skilling, placement preparation, emerging tech skills like data science, digital marketing, full stack web development and more. Read on...',
    keywords: 'Full Stack development, Data Science, Digital Marketing',
    image: '',
    url: 'https://www.crampete.com/blogs',
    author: 'Crampete'
  }


const Category = ({ data }) => {


    return (
        <Layout title={seo.title} description={seo.description} image={seo.image} keywords={seo.keywords} url={seo.url} author={seo.author}>
    <Jumbotron className="bg-lightblue">
      <div className="container">
        <div style={{maxWidth:"600px",textAlign:"center",margin:"auto"}}>
          <h2>Blogs  {data.allBlogDataset.edges[0].node.Category && '| '+ data.allBlogDataset.edges[0].node.Category}</h2>
          <p className="mt-4">
          Keep up with latest and greatest developments in up-skilling with our handcrafted articles, carefully put together for you.
          </p>
        </div>
      </div>
    </Jumbotron>
     <div className="container">
        <div>
         <div className="row mb-5"> 
            {
                data.allBlogDataset.edges.map(i => {
                    return <Md4Card title={i.node.Title} excerpt={i.node.Excerpt} slugURL={i.node.SlugURL} featuredImageURL={i.node.FeaturedImageURL} category={i.node.Category} />
                })
            }
        </div>
        </div>
      </div>
    </Layout>
    )
}


export default Category